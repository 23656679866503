<template>
  <el-dialog
    :close-on-click-modal="clickClose" :destroy-on-close="closeFree" :title="title" :visible.sync="_value"
    :width="width" append-to-body
    class="border" custom-class="custom-dialog"
  >
    <slot></slot>
    <div v-if="showFooter" slot="footer">
      <v-btn class="mr8" color="primary" outlined small @click="_value = false; $emit('cancel')">{{ cancelText }}</v-btn>
      <v-btn color="primary" small style="background-color: #9155fd; color: white" @click="$emit('ok')">{{ okText }}</v-btn>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'WDialog',
  install(Vue) {
    Vue.component('WDialog', this)
  },
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '50%',
    },
    value: {
      type: Boolean,
      default: false,
    },
    clickClose: {
      type: Boolean,
      default: false,
    },
    closeFree: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: '取 消',
    },
    okText: {
      type: String,
      default: '确 定',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    _value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
::v-deep .custom-dialog {
  .el-dialog__header {
    padding: 10px 20px;
    .el-dialog__title{
      font-size: 17px;
    }
    .el-dialog__headerbtn{
      top: 15px;
      .i{
        font-size: large;
      }
    }
  }

  .el-dialog__footer {
    padding: 10px 20px;
  }
}

 .border {
   ::v-deep .el-dialog__header {
    border-bottom: 1px solid #e8e8e8;
  }
   ::v-deep .el-dialog__footer {
    border-top: 1px solid #e8e8e8;
  }
}
</style>

const { default: mock } = require('@/@fake-db/mock')

const data = {
  works: [
    {
      id: 1,
      channelId: 1,
      channelName: '尾巴殿下',
      name: '尾巴殿下123',
      videoNumber: '尾巴殿下治愈合集001',
      type: '普通',
      planStart: '2022-01-19 09:00',
      planEnd: '2022-01-19 18:30',
      startAt: '2022-01-19 10:00',
      endAt: '2022-01-19 18:28',
      status: '已删除',
      isOvertime: 0,
      overtimeTime: '',
      staff: '小明',
    },
    {
      id: 2,
      channelId: 1,
      channelName: '尾巴殿下',
      name: '尾巴殿下345',
      videoNumber: '尾巴殿下治愈合集888',
      type: '普通',
      planStart: '2022-01-19 09:00',
      planEnd: '2022-01-19 18:30',
      startAt: '2022-01-19 10:00',
      endAt: '2022-01-19 18:31',
      status: '已完成',
      isOvertime: 1,
      overtimeTime: '1分',
      staff: '小刚',
    },
    {
      id: 3,
      channelId: 2,
      channelName: '深夜读书',
      name: '深夜读书123',
      videoNumber: '深夜读书合集001',
      type: '紧急',
      planStart: '2022-01-19 09:00',
      planEnd: '2022-01-19 18:30',
      startAt: '2022-01-19 10:00',
      endAt: '2022-01-19 18:28',
      status: '已完成',
      isOvertime: 0,
      overtimeTime: '',
      staff: '小红',
    },
    {
      id: 4,
      channelId: 2,
      channelName: '深夜读书',
      name: '深夜读书222',
      videoNumber: '深夜读书合集007',
      type: '囤货',
      planStart: '2022-01-19 09:00',
      planEnd: '2022-01-19 18:30',
      startAt: '2022-01-19 10:00',
      endAt: '2022-01-19 18:33',
      status: '进行中',
      isOvertime: 1,
      overtimeTime: '3分',
      staff: '小亮',
    },
  ],
}

mock.onGet('/task/task/pages').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', options = {}, status = null, role = null, plan = null } = config.params

  // eslint-disable-next-line object-curly-newline
  const { sortBy = [], sortDesc = [], page = 0, itemsPerPage = 0 } = options
  /* eslint-enable */

  const queryLowered = q.toLowerCase()

  let filteredData = data.works.filter(
    work =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (work.name.toLowerCase().includes(queryLowered) || work.fullName.toLowerCase().includes(queryLowered)) &&
      work.role === (role || work.role) &&
      work.currentPlan === (plan || work.currentPlan) &&
      work.status === (status || work.status),
  )
  /* eslint-enable  */

  // sorting
  if (sortBy.length === 1 && sortDesc.length === 1) {
    filteredData = filteredData.sort((a, b) => {
      const sortA = a[sortBy[0]]
      const sortB = b[sortBy[0]]

      if (sortDesc[0]) {
        if (sortA < sortB) return 1
        if (sortA > sortB) return -1

        return 0
      }

      if (sortA < sortB) return -1
      if (sortA > sortB) return 1

      return 0
    })
  }

  return [
    200,
    {
      // filteredData:
      //   itemsPerPage !== -1 ? filteredData.slice((page - 1) * itemsPerPage, page * itemsPerPage) : filteredData,
      // total: filteredData.length,
      data,
      total: data.length,
    },
  ]
})

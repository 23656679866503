/* eslint-disable import/extensions */
import './data/apps/calendar'
import './data/apps/chat'
import './data/apps/email'
import './data/apps/invoice'
import './data/apps/user'
import './data/work/work'

// import './data/task/employee'
// import './data/task/progress'
// import './data/task/todo'
// import './data/task/todoDetail'
import './data/system/menu'
import './data/system/route'
// import './data/table/datatable'
// import './data/cron/cronList'

import './jwt/index'
import mock from './mock'

/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network

const { default: mock } = require('@/@fake-db/mock')

const dataJson = `{
  "status": 200,
  "message": "ok",
  "data": [
    {
      "subheader": "system"
    },
    {
      "id": 1,
      "name": "系统配置",
      "parentId": 0,
      "icon": "mdiCogOutline",
      "groupName": "system",
      "children": [
        {
          "id": 2,
          "name": "菜单设置",
          "parentId": 1,
          "icon": "",
          "groupName": "system",
          "route": "menu-list"
        },
        {
          "id": 3,
          "name": "系统设置",
          "parentId": 1,
          "icon": "",
          "groupName": "system",
          "route": "config-list"
        },
        {
          "id": 4,
          "name": "配置属性",
          "parentId": 1,
          "icon": "",
          "groupName": "system",
          "route": "config-setting"
        }
      ]
    },
    {
      "id": 5,
      "name": "用户管理",
      "parentId": 0,
      "icon": "mdiAccountOutline",
      "groupName": "system",
      "children": [
        {
          "id": 6,
          "name": "部门成员",
          "parentId": 5,
          "icon": "",
          "groupName": "system",
          "route": "user-list"
        },
        {
          "id": 7,
          "name": "角色组",
          "parentId": 5,
          "icon": "",
          "groupName": "system",
          "route": "role-list"
        }
      ]
    },
    {
      "subheader": "application"
    },
    {
      "id": 44,
      "name": "发送管理",
      "parentId": 0,
      "icon": "mdiEmailSendOutline",
      "groupName": "application",
      "children": [
        {
          "id": 45,
          "name": "短信发送",
          "parentId": 44,
          "icon": "",
          "groupName": "application",
          "route": "sms-list"
        },
        {
          "id": 46,
          "name": "钉钉发送",
          "parentId": 44,
          "icon": "",
          "groupName": "application",
          "route": "dingding-list"
        }
      ]
    },
    {
      "id": 47,
      "name": "通讯录",
      "parentId": 0,
      "icon": "mdiAccountBoxMultipleOutline",
      "groupName": "application",
      "children": [
        {
          "id": 48,
          "name": "内部员工",
          "parentId": 47,
          "icon": "",
          "groupName": "application",
          "route": "staff-list"
        },
        {
          "id": 49,
          "name": "兼职员工",
          "parentId": 47,
          "icon": "",
          "groupName": "application",
          "route": "parttime-list"
        },
        {
          "id": 50,
          "name": "离职员工",
          "parentId": 47,
          "icon": "",
          "groupName": "application",
          "route": "resigned-list"
        }
      ]
    },
    {
      "id": 51,
      "name": "部门属性",
      "parentId": 0,
      "icon": "mdiBriefcaseAccountOutline",
      "groupName": "application",
      "route": "department-list"
    },
    {
      "id": 52,
      "name": "管理员账号配置",
      "parentId": 0,
      "icon": "mdiAccountKeyOutline",
      "groupName": "application",
      "route": "adminAccountConfig-list"
    },
    {
      "id": 53,
      "name": "业务架构配置",
      "parentId": 0,
      "icon": "mdiFileTreeOutline",
      "groupName": "application",
      "route": "businessFrame-list"
    },
    {
      "id": 54,
      "name": "角色管理",
      "parentId": 0,
      "icon": "mdiAccountCowboyHatOutline",
      "groupName": "application",
      "route": "roleManagement-list"
    },
    {
      "id": 55,
      "name": "子系统权限配置",
      "parentId": 0,
      "icon": "mdiAccountLockOpenOutline",
      "groupName": "application",
      "route": "permissionConfigure-list"
    },
    {
      "id": 15,
      "name": "消息队列",
      "parentId": 0,
      "icon": "mdiMessageTextOutline",
      "groupName": "application",
      "route": "application-message"
    }
  ]
}`

mock.onGet('/base/menu').reply(() => {
  const data = JSON.parse(dataJson)

  return [
    200,
    {
      data: data.data,
      total: data.data.length,
    },
  ]
})

<script>
export default {
  name: 'AppTitle',
  props: ['text'],
  computed: {
    title() {
      return this.text || this.$route.meta?.pageTitle || ''
    },
  },
}
</script>

<template>
  <div
    v-show="title"
    class="text-h7 font-weight-black title-line"
  >
    {{ title }}
  </div>
</template>

<style scoped lang='scss'>
.title-line {
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    width: 6px;
    height: 100%;
    background-color: var(--v-primary-base);
  }
}
</style>

const base = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/ssoLogin',
    name: 'sso-login',
    component: () => import('@/views/NewSSOLogin.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/choseSys',
    name: 'chose-sys',
    component: () => import('@/views/entry/index.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
    redirect: '/MyApps',
    children: [
      {
        path: '/MyApps',
        name: 'my-apps',
        component: () => import('@/views/ChoseSysNew.vue'),
        meta: {
          layout: 'blank',
          title: '我的应用',
        },
      },
      {
        path: '/WorkFlow',
        name: 'work-flow',
        component: () => import('@/views/workFlow/index.vue'),
        meta: {
          layout: 'blank',
          title: '我的审批',
        },
      },
      {
        path: '/WorkFlowDetails',
        name: 'work-flow-details',
        component: () => import('@/views/workFlow/Details.vue'),
        meta: {
          layout: 'blank',
          title: '审批详情',
          activeMenu: '/WorkFlow',
        },
      },
    ],
  },
  {
    path: '/processManagement/Add',
    name: 'process-add',
    component: () => import('@/views/processManagement/Add.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // 用户相关信息
  {
    path: '/pages/account-settings',
    name: 'page-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
    },
  },

  // 系统帮助
  {
    path: '/pages/faq',
    name: 'page-faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      layout: 'content',
    },
  },

  // 邮件
]

export default base

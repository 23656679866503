// import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    name: '控制台首页',
    icon: 'mdiHomeOutline',
    badgeColor: 'warning',
    route: 'dashboard-eCommerce',
  },
]

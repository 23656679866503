import Swal from 'sweetalert2'

export const toastSuccess = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'success',
    title: message,
  })
}
export const toastInfo = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'info',
    title: message,
  })
}
export const toastFail = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'error',
    title: message,
  })
}

export const modalSuccess = message => Swal.fire(
  '成功!',
  message,
  'success',
)
export const modalFail = message => Swal.fire(
  '失败!',
  message || '未知错误，请联系管理员.',
  'error',
)

export const modalEnquire = message => Swal.fire({
  title: '请确认所填信息无误',
  text: message,
  icon: 'warning',
  showCancelButton: true,
  reverseButtons: true,
  confirmButtonText: '确认',
  cancelButtonText: '取消',
})

export const modalEnquireTitle = (title, message = '') => Swal.fire({
  title,
  text: message,
  icon: 'warning',
  showCancelButton: true,
  reverseButtons: true,
  confirmButtonText: '确认',
  cancelButtonText: '取消',
})

import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import XWUI from '@prettyy/ui'
import VXETable from 'vxe-table'
import vueSelect from 'vue-select'
import Ellipsis from '@/components/Ellipsis.vue'
import WDialog from '@/components/WDialog.vue'
import ElementUI from 'element-ui'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import 'vue-select/dist/vue-select.css'
import '@core/components-styles/ckEditorCustom.css'
import '@core/components-styles/vueSelectCustom.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@prettyy/ui/dist/css/index.css'

import 'vxe-table/lib/style.css'

Vue.component('VueSelect', vueSelect)

// vxeTable
Vue.use(VXETable)
Vue.use(ElementUI)
Vue.use(Ellipsis)
Vue.use(WDialog)
Vue.use(XWUI)

Vue.config.productionTip = false
Vue.prototype.$isNotEmpty = function (obj) {
  return (obj !== undefined && obj !== null && obj !== '' && obj !== 'null')
}
Vue.prototype.$deepCopy = function (obj) { return JSON.parse(JSON.stringify(obj)) }

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')

const { default: mock } = require('@/@fake-db/mock')

const dataJson = `{
  "status": 200,
  "message": "ok",
  "data": [
    {
      "path": "/system/menu/List",
      "route": "menu-list",
      "name": "菜单设置",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "menu-list",
        "pageTitle": "菜单设置",
        "breadcrumb": [
          {
            "text": "系统配置",
            "active": true
          },
          {
            "text": "菜单设置",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/system/config/List",
      "route": "config-list",
      "name": "系统设置",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "config-list",
        "pageTitle": "系统设置",
        "breadcrumb": [
          {
            "text": "系统配置",
            "active": true
          },
          {
            "text": "系统设置",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/system/config/Setting",
      "route": "config-setting",
      "name": "配置属性",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "config-setting",
        "pageTitle": "配置属性",
        "breadcrumb": [
          {
            "text": "系统配置",
            "active": true
          },
          {
            "text": "配置属性",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/system/user/List",
      "route": "user-list",
      "name": "部门成员",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "user-list",
        "pageTitle": "部门成员",
        "breadcrumb": [
          {
            "text": "用户管理",
            "active": true
          },
          {
            "text": "部门成员",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/system/role/List",
      "route": "role-list",
      "name": "角色组",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "role-list",
        "pageTitle": "角色组",
        "breadcrumb": [
          {
            "text": "用户管理",
            "active": true
          },
          {
            "text": "角色组",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/system/role/Permission",
      "route": "role-permission",
      "name": "分配权限",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "role-list",
        "pageTitle": "分配权限",
        "breadcrumb": [
          {
            "text": "用户管理",
            "active": true
          },
          {
            "text": "角色组",
            "active": true
          },
          {
            "text": "分配权限",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/point/List",
      "route": "work-point",
      "name": "任务节点",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "任务节点",
        "breadcrumb": [
          {
            "text": "任务节点",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/point/attr/List",
      "route": "work-point-attr",
      "name": "节点属性",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "节点属性",
        "breadcrumb": [
          {
            "text": "任务节点",
            "active": true
          },
          {
            "text": "节点属性",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/template/List",
      "route": "work-template",
      "name": "任务模板",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "任务模板",
        "breadcrumb": [
          {
            "text": "任务模板",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/template/point/Detail",
      "route": "work-template-detail",
      "name": "模板详情",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "模板详情",
        "breadcrumb": [
          {
            "text": "任务模板",
            "active": true
          },
          {
            "text": "模板详情",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/message/List",
      "route": "application-message",
      "name": "消息队列",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "消息队列",
        "breadcrumb": [
          {
            "text": "消息队列",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/todo/Submit",
      "route": "work-todo-submit",
      "name": "任务处理",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "任务处理",
        "breadcrumb": [
          {
            "text": "任务处理",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/todo/Detail",
      "route": "work-todo-detail",
      "name": "任务详情",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "任务详情",
        "breadcrumb": [
          {
            "text": "任务详情",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/cron/AddNew",
      "route": "cron-list-add",
      "name": "新增任务",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "新增任务",
        "breadcrumb": [
          {
            "text": "新增任务",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/cron/Edit",
      "route": "cron-list-edit",
      "name": "编辑任务",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "编辑任务",
        "breadcrumb": [
          {
            "text": "编辑任务",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/cron/Detail",
      "route": "cron-list-detail",
      "name": "任务详情",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "任务详情",
        "breadcrumb": [
          {
            "text": "任务详情",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/work/AddNew",
      "route": "work-list-add",
      "name": "新增流程",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "新增流程",
        "breadcrumb": [
          {
            "text": "新增流程",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/work/Edit",
      "route": "work-list-edit",
      "name": "编辑流程",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "编辑流程",
        "breadcrumb": [
          {
            "text": "编辑流程",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/work/Detail",
      "route": "work-list-detail",
      "name": "流程详情",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "流程详情",
        "breadcrumb": [
          {
            "text": "流程详情",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/work/task/Detail",
      "route": "work-task-detail",
      "name": "待办清单详情",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "待办清单详情",
        "breadcrumb": [
          {
            "text": "待办清单详情",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/sms/List",
      "route": "sms-list",
      "name": "短信发送",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "sms-list",
        "pageTitle": "短信发送",
        "breadcrumb": [
          {
            "text": "发送管理",
            "active": true
          },
          {
            "text": "短信发送",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/dingding/List",
      "route": "dingding-list",
      "name": "钉钉发送",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "dingding-list",
        "pageTitle": "钉钉发送",
        "breadcrumb": [
          {
            "text": "发送管理",
            "active": true
          },
          {
            "text": "钉钉发送",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/staff/List",
      "route": "staff-list",
      "name": "内部员工",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "staff-list",
        "pageTitle": "内部员工",
        "breadcrumb": [
          {
            "text": "通讯录",
            "active": true
          },
          {
            "text": "内部员工",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/parttime/List",
      "route": "parttime-list",
      "name": "兼职员工",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "parttime-list",
        "pageTitle": "兼职员工",
        "breadcrumb": [
          {
            "text": "通讯录",
            "active": true
          },
          {
            "text": "兼职员工",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/resigned/List",
      "route": "resigned-list",
      "name": "离职员工",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "navActiveLink": "resigned-list",
        "pageTitle": "离职员工",
        "breadcrumb": [
          {
            "text": "通讯录",
            "active": true
          },
          {
            "text": "离职员工",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/department/List",
      "route": "department-list",
      "name": "部门属性",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "部门属性",
        "breadcrumb": [
          {
            "text": "部门属性",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/adminAccountConfig/List",
      "route": "adminAccountConfig-list",
      "name": "管理员账号配置",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "管理员账号配置",
        "breadcrumb": [
          {
            "text": "管理员账号配置",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/businessFrame/List",
      "route": "businessFrame-list",
      "name": "业务架构配置",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "业务架构配置",
        "breadcrumb": [
          {
            "text": "业务架构配置",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/roleManagement/List",
      "route": "roleManagement-list",
      "name": "角色管理",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "角色管理",
        "breadcrumb": [
          {
            "text": "角色管理",
            "active": true
          }
        ]
      }
    },
    {
      "path": "/application/permissionConfigure/List",
      "route": "permissionConfigure-list",
      "name": "子系统权限配置",
      "meta": {
        "layout": "content",
        "resource": "ACL",
        "action": "read",
        "pageTitle": "子系统权限配置",
        "breadcrumb": [
          {
            "text": "子系统权限配置",
            "active": true
          }
        ]
      }
    }
  ]
}`

mock.onGet('/base/route').reply(() => {
  const data = JSON.parse(dataJson)

  return [
    200,
    {
      data: data.data,
      total: data.data.length,
    },
  ]
})
